import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_vm._v("Agregar modelo educativo")]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VCardText,[_c(VSelect,{attrs:{"label":"Modelo:","items":_vm.modelosDisponibles,"item-text":"nombre","item-value":"_id","outlined":"","dense":""},model:{value:(_vm.modeloSeleccionado),callback:function ($$v) {_vm.modeloSeleccionado=$$v},expression:"modeloSeleccionado"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{class:{ 'disable-btn': !_vm.modeloSeleccionado },attrs:{"color":"primary","small":"","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.agregarModelo()}}},[_vm._v("Agregar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }