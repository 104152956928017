<template>
  <v-card>
    <v-toolbar color="secondary" dense dark flat class="text-h6"
      >Administradores
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="loading"
            @click="agregarAdmin = true"
          >
            <v-icon>mdi-playlist-plus</v-icon>
          </v-btn>
        </template>
        <span>Agregar administrador</span>
      </v-tooltip>
    </v-toolbar>

    <v-sheet v-if="loading" class="pa-3">
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar-two-line@3"
      ></v-skeleton-loader>
    </v-sheet>

    <v-list v-else>
      <div v-for="(user, index) in administradores" :key="index">
        <v-list-item>
          <v-list-item-avatar>
            <v-img v-if="user.avatar" :src="`${imageURL}/icon/${user.avatar}`" />
            <v-icon v-else size="45">mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <switchMiniComponent
                :active="user.activo"
                :loading="user.loading"
                @activate="modificarActivoUsuario(user.idUsuario)"
                @deactivate="modificarActivoUsuario(user.idUsuario)"
              />
              {{ user.nombreCompleto }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.correo }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <eliminarAdministradorComponent
              :idUsuarioEscuela="user._id"
              :nombreUsuario="user.nombreCompleto"
              @usuarioEliminado="usuarioEliminado"
            />
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>

    <buscarUsuarioComponent
      :mostrar="agregarAdmin"
      :loading="loadingAgregarAdmin"
      :titulo="'Agregar administrador'"
      :excluir="excluirBusqueda"
      :ruta="'usuarios/busqueda'"
      mostrarCorreo
      cerrarAlSeleccionar
      @loadingStart="loadingAgregarAdmin = true"
      @loadingStop="loadingAgregarAdmin = false"
      @cerrar="agregarAdmin = false"
      @usuarioAction="agregarUsuario"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  getAdministradoresEscuela,
  agregarUsuarioService,
  modificarActivoUsuarioService,
} from "./escuelas.service";

import switchMiniComponent from "../globals/template/widgets/switchMini.vue";
import buscarUsuarioComponent from "../globals/template/widgets/usuarios/buscarUsuario.vue";
import eliminarAdministradorComponent from "./eliminarAdminEscuela.vue";

export default {
  name: "administradoresEscuela",
  components: {
    switchMiniComponent,
    buscarUsuarioComponent,
    eliminarAdministradorComponent,
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["imageURL", "sessionToken"]),
    administradores() {
      let administradores = [];
      this.usuariosEscuela.forEach((administrador) => {
        let admin = {
          _id: administrador._id,
          invitacionAceptada: administrador.invitacionAceptada,
          nombreCompleto: administrador.usuario.nombreCompleto,
          idUsuario: administrador.usuario._id,
          correo: administrador.usuario.correo,
          avatar: administrador.usuario.avatar,
          activo: administrador.activo,
          loading: administrador.loading,
        };
        administradores.push(admin);
      });
      return administradores;
    },
    excluirBusqueda() {
      return this.administradores.map((x) => {
        return x.idUsuario;
      });
    },
  },
  data() {
    return {
      loading: true,
      idEscuela: null,
      agregarAdmin: false,
      quitarAdmin: false,
      loadingAgregarAdmin: false,
      usuariosEscuela: [],
    };
  },
  watch: {
    sessionToken(value) {
      if (value) this.getAdministradores();
    },
  },
  created() {
    this.idEscuela = this.$route.params.idEscuela;
    if (this.sessionToken) this.getAdministradores();
  },
  methods: {
    async getAdministradores() {
      try {
        this.loading = true;
        const serverResponse = await getAdministradoresEscuela(this.idEscuela);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.usuariosEscuela = serverResponse.administradores.map((x) => {
            return { ...x, loading: false };
          });
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async agregarUsuario(idUsuario) {
      try {
        this.loadingAgregarAdmin = true;
        const serverResponse = await agregarUsuarioService(
          this.idEscuela,
          idUsuario
        );
        this.loadingAgregarAdmin = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.usuariosEscuela.push(serverResponse.usuarioEscuela);
      } catch (error) {
        this.loadingAgregarAdmin = false;
        this.$appErrorMessage();
      }
    },
    async modificarActivoUsuario(idUsuario) {
      const indexUsuario = this.usuariosEscuela.findIndex(
        (x) => x.usuario._id == idUsuario
      );
      try {
        this.usuariosEscuela[indexUsuario].loading = true;
        const sendData = {
          idEscuela: this.idEscuela,
          idUsuario,
          activo: !this.usuariosEscuela[indexUsuario].activo,
        };
        const serverResponse = await modificarActivoUsuarioService(sendData);
        this.usuariosEscuela[indexUsuario].loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.usuariosEscuela[indexUsuario].activo = !this.usuariosEscuela[indexUsuario].activo
      } catch (error) {
        this.usuariosEscuela[indexUsuario].loading = false;
        this.$appErrorMessage();
      }
    },
    async usuarioEliminado(idUsuarioEscuela) {
      const index = this.usuariosEscuela.findIndex(
        (x) => x._id == idUsuarioEscuela
      );
      if (index >= 0) this.usuariosEscuela.splice(index, 1);
    },
  },
};
</script>
