<template>
  <v-card shaped>
    <v-toolbar dense flat>
      <v-btn icon color="primary" @click="goBack()">
        <v-icon>mdi-arrow-left-bold</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-switch
        v-model="escuela.activa"
        inset
        color="green darken-2"
        :disabled="loading"
        :loading="loadingSwitch"
        style="padding-top: 25px;"
        @click.prevent="activarEscuela()"
      ></v-switch>
    </v-toolbar>
    <v-col class="justify-center text-center">
      <v-img
        v-if="escuela.logo"
        :src="`${thumbsURL}/${escuela.logo}`"
        alt="user-avatar"
        contain
        max-height="300"
      ></v-img>
      <v-avatar v-else size="250">
        <v-icon size="230">mdi-school</v-icon>
      </v-avatar>
    </v-col>

    <v-sheet v-if="loading" class="pa-3">
      <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="list-item-avatar-two-line@3"
      ></v-skeleton-loader>
    </v-sheet>

    <v-list v-else dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Nombre</v-list-item-subtitle>
          <v-list-item-title>{{ escuela.nombre }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Nombre corto</v-list-item-subtitle>
          <v-list-item-title>{{ escuela.nombreCorto }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Bucket/DB</v-list-item-subtitle>
          <v-list-item-title>{{ escuela.bucket }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Modelos educativos</v-list-item-subtitle>
          <v-list-item-title v-if="escuela.modelosEducativos.length == 0">
            <span style="color: #A00">No hay modelo educativo asignado</span>
          </v-list-item-title>
          <template v-else>
            <v-list-item-title
              v-for="modelo in escuela.modelosEducativos"
              :key="`modelo-${modelo._id}`"
              >{{ modelo.nombre }}</v-list-item-title
            >
          </template>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            color="primary"
            small
            :disabled="
              modelosEducativos.length == escuela.modelosEducativos.length
            "
            @click="agregarModelo = true"
            ><v-icon>mdi-playlist-plus</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Profesores</v-list-item-subtitle>
          <v-list-item-title
            >{{ escuela.profesores }} /
            {{ escuela.profesoresPermitidos }}</v-list-item-title
          >
          <v-progress-linear value="0"></v-progress-linear>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon color="primary" small @click="modificarProfesores=true"
            ><v-icon>mdi-pencil-circle-outline</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Alumnos</v-list-item-subtitle>
          <v-list-item-title
            >{{ escuela.alumnos }} /
            {{ escuela.alumnosPermitidos }}</v-list-item-title
          >
          <v-progress-linear value="0"></v-progress-linear>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon color="primary" small @click="modificarAlumnos=true"
            ><v-icon>mdi-pencil-circle-outline</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>Espacio</v-list-item-subtitle>
          <v-list-item-title>0 / 100 GB</v-list-item-title>
          <v-progress-linear value="0"></v-progress-linear>
        </v-list-item-content>
        <v-list-item-action>
          <!-- <v-btn icon color="primary" small
            ><v-icon>mdi-pencil-circle-outline</v-icon></v-btn
          > -->
        </v-list-item-action>
      </v-list-item>
     
    </v-list>

    <agregarModeloEducativo
      v-if="agregarModelo"
      :mostrar="agregarModelo"
      :idEscuela="idEscuela"
      :modelosEscuela="escuela.modelosEducativos"
      :modelosEducativos="modelosEducativos"
      @cancelar="agregarModelo = false"
      @modeloAgregado="modeloAgregado"
    />

    <modificarProfesoresComponent 
      v-if="modificarProfesores"
      :mostrar="modificarProfesores"
      :idEscuela="escuela._id"
      :profesores="escuela.profesoresPermitidos"
      @cancelar="modificarProfesores=false"
      @profesoresModificado="profesoresModificado"
    />
    <modificarAlumnosComponent 
      v-if="modificarAlumnos"
      :mostrar="modificarAlumnos"
      :idEscuela="idEscuela"
      :alumnos="escuela.alumnosPermitidos"
      @cancelar="modificarAlumnos=false"
      @alumnosModificado="alumnosModificado"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import _ from 'underscore';
import { getDatosEscuela, activarEscuelaService } from "./escuelas.service";
import agregarModeloEducativo from "./agregarModeloEducativo.vue";
import modificarProfesoresComponent from "./modificarProfesores.vue";
import modificarAlumnosComponent from "./modificarAlumnos.vue";


export default {
  name: "datosEscuela",
  components: { agregarModeloEducativo, modificarProfesoresComponent, modificarAlumnosComponent },
  computed: {
    ...mapGetters(["imageURL", "sessionToken"]),
  },
  data: () => ({
    loading: true,
    loadingSwitch: false,
    idEscuela: null,
    escuela:{},
    modelosEducativos: [],
    agregarModelo: false,
    modificarProfesores: false,
    modificarAlumnos: false,
  }),
  watch: {
    sessionToken(value) {
      if (value) this.cargarDatosEscuela();
    },
  },
  created() {
    this.idEscuela = this.$route.params.idEscuela;
    if (this.sessionToken) this.cargarDatosEscuela();
  },
  methods: {
    async cargarDatosEscuela() {
      try {
        this.loading = true;
        const serverResponse = await getDatosEscuela(this.idEscuela);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {          
          this.modelosEducativos = serverResponse.modelosEducativos;
          this.escuela = serverResponse.escuela;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async activarEscuela() {
      try {
        this.loadingSwitch = true;
        const serverResponse = await activarEscuelaService(this.escuela._id);
        this.loadingSwitch = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.escuela.activa = serverResponse.escuelaActiva;
      } catch (error) {
        this.loadingSwitch = false;
        this.escuela.activa = !this.escuela.activa;
        this.$appErrorMessage();
      }
    },
    modeloAgregado(modelo) {
      this.escuela.modelosEducativos.push(modelo);
      this.agregarModelo = false;
    },
    profesoresModificado(profesoresPermitidos){
      this.escuela.profesoresPermitidos = profesoresPermitidos;
      this.modificarProfesores = false
    },
    alumnosModificado(alumnosPermitidos){
      this.escuela.alumnosPermitidos = alumnosPermitidos;
      this.modificarAlumnos = false
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>
