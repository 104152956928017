import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_vm._v("Alumnos")]),_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VCardText,[_c(VTextField,{attrs:{"label":"Número máximo:","outlined":"","dense":"","disabled":_vm.loading,"error-messages":_vm.numeroErrors},on:{"input":function($event){return _vm.$v.numero.$touch()},"blur":function($event){return _vm.$v.numero.$touch()}},model:{value:(_vm.numero),callback:function ($$v) {_vm.numero=$$v},expression:"numero"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{class:{ 'disable-btn': _vm.disableBtn },attrs:{"color":"primary","small":"","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarAlumnos()}}},[_vm._v("Agregar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }