<template>
  <v-dialog v-model="mostrar" persistent max-width="400">
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark class="text-h6"
        >Agregar modelo educativo</v-toolbar
      >
      <v-form v-on:submit.prevent>
        <v-card-text>
          <v-select
            v-model="modeloSeleccionado"
            label="Modelo:"
            :items="modelosDisponibles"
            item-text="nombre"
            item-value="_id"
            outlined
            dense
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="$emit('cancelar')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            small
            dark
            :loading="loading"
            :class="{ 'disable-btn': !modeloSeleccionado }"
            @click="agregarModelo()"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { agregarModeloEducativo } from "./escuelas.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    idEscuela: { type: String, default: '' },
    modelosEscuela: { type: Array, default: () => [] },
    modelosEducativos: { type: Array, default: () => [] },
  },
  computed: {
    modelosDisponibles() {
      return this.modelosEducativos.filter((modelo) => {
        return this.modelosEscuela.findIndex((x) => x._id == modelo._id) < 0;
      });
    },
  },
  data() {
    return {
      loading: false,
      modelos: [],
      modeloEducativo: null,
      modeloSeleccionado: null,
    };
  },
  mounted() {
    if (this.modelosDisponibles.length > 0)
      this.modeloSeleccionado = this.modelosDisponibles[0]._id;
  },
  methods: {
    async agregarModelo() {
      try {
        this.loading = true;
        const serverResponse = await agregarModeloEducativo(this.idEscuela, this.modeloSeleccionado);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("modeloAgregado", serverResponse.modelo);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
