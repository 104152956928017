import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","flat":"","dark":"","color":"primary"}},[_vm._v("Quitar administrador ")]),(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"secondary"}}):_vm._e(),_c(VContainer,[_vm._v("¿Está seguro en quitar a "+_vm._s(_vm.nombreUsuario)+" como administrador?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){_vm.mostrar=false}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","dark":"","color":"red darken-2","loading":_vm.loading},on:{"click":function($event){return _vm.quitarAdministrador()}}},[_vm._v("Quitar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }