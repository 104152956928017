<template>
  <v-dialog v-model="mostrar" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small icon v-bind="attrs" v-on="on">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dense flat dark color="primary" class="text-h6"
        >Quitar administrador
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-container
        >¿Está seguro en quitar a {{nombreUsuario}} como administrador?</v-container
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="mostrar=false"
          >Cancelar</v-btn
        >
        <v-btn
          small
          dark
          color="red darken-2"
          :loading="loading"
          @click="quitarAdministrador()"
          >Quitar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { quitarAdministradorService } from "./escuelas.service";

export default {
  name: "eliminarAdministrador",
  props: {
    idUsuarioEscuela: String,
    nombreUsuario: String,
  },
  data() {
    return {
      mostrar: false,
      loading: false,
    };
  },
  methods: {
    async quitarAdministrador() {
      try {
        this.loading = true;
        const serverResponse = await quitarAdministradorService(
          this.idUsuarioEscuela
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
            this.$emit("usuarioEliminado", this.idUsuarioEscuela);
            this.mostrar = false;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
