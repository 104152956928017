<template>
  <v-dialog v-model="mostrar" persistent max-width="300">
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark class="text-h6"
        >Alumnos</v-toolbar
      >
      <v-form v-on:submit.prevent>
        <v-card-text>
          <v-text-field
            label="Número máximo:"
            v-model="numero"
            outlined
            dense
            :disabled="loading"
            :error-messages="numeroErrors"
            @input="$v.numero.$touch()"
            @blur="$v.numero.$touch()"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            outlined
            :disabled="loading"
            @click="$emit('cancelar')"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            small
            dark
            :loading="loading"
            :class="{ 'disable-btn': disableBtn }"
            @click="modificarAlumnos()"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer, minValue } from "vuelidate/lib/validators";
import { modificarAlumnosService } from "./escuelas.service";

export default {
  props: {
    mostrar: Boolean,
    alumnos: Number,
    idEscuela: String
  },
  mixins: [validationMixin],
  computed: {
    numeroErrors() {
      const errors = [];
      if (!this.$v.numero.$dirty) return errors;
      !this.$v.numero.minValue &&
        errors.push("Debe ingresar un número positivo.");
      !this.$v.numero.integer && errors.push("Debe inresar un número.");
      !this.$v.numero.required && errors.push("Campo requerido.");
      return errors;
    },
    disableBtn() {
      if (this.numeroErrors.length > 0) return true;
      return false;
    },
  },
  validations: {
    numero: { required, integer, minValue: minValue(0) },
  },
  data() {
    return {
      loading: false,
      numero: null,
    };
  },
  mounted() {
    this.numero = this.alumnos;
  },
  methods: {
    async modificarAlumnos() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const sendData = {
            idEscuela: this.idEscuela,
            alumnosPermitidos: this.numero
          }
          const serverResponse = await modificarAlumnosService(sendData);
          this.loading = false;
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else this.$emit("alumnosModificado", this.numero);
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
